import * as React from "react";
import Login from "./auth/Login";
import { auth } from "./data/fire";
import { onAuthStateChanged } from "firebase/auth";
import { HashRouter, Route, Routes } from "react-router-dom";
// Auth
import Layout from "./layouts/Layout";
import LayoutSplash from "./layouts/LayoutSplash";
// Siniestralidad
import Siniestralidad from "./paginas/Admin/Sin/Siniestralidad";
import Reporte1 from "./paginas/Admin/Sin/Reporte1"
import Modulosfrm from "./paginas/Admin/Adm/Modulosfrm";
//Escritorio
import Splash2 from "./paginas/Admin/Esc/Splash2";
// Error404
import Error404 from "./Error404";

// Configuracion

function App() {
  const [usuario, setUsuario] = React.useState(null);
  const [authState, setAuthState] = React.useState(null);
  const [autusuario, setAutUsuario] = React.useState([]);
  const perfil = [
    {
      email: "",
    },
  ];
  React.useEffect(() => {
    const unSuscribirAuth = onAuthStateChanged(
      auth,
      async (authenticatedUser) => {
        if (authenticatedUser) {
          setAutUsuario(authenticatedUser);
          setUsuario(authenticatedUser);
          perfil.email = authenticatedUser.email;
          setAuthState("Dashboard");
        } else {
          setUsuario(null);
          setAuthState("login");
        }
      }
    );

    return unSuscribirAuth;
  }, [usuario]);

  if (authState === null)
    return (
      <div className="font-bold text-center text-5xl py-[15vh]">
        Cargando...
      </div>
    );
  if (authState === "login")
    return <Login setAuthState={setAuthState} autusuario={autusuario} />;
  if (usuario)
    return (
      <div>
        <HashRouter>
        <Routes>
            <Route>
            <Route path="/" element={<LayoutSplash autusuario={autusuario}/>}>
            <Route index elemnt={<Splash2  autusuario={autusuario}/>}/>      
              </Route> 
              <Route path="/" element={<Layout autusuario={autusuario} />}>
                <Route index elemnt={<Splash2  autusuario={autusuario}/>}/>               
                {/* Siniestralidad */}
                <Route path="sin/reporte1" element={<Reporte1 />}/>
                <Route path="sin/siniestralidad" element={<Siniestralidad />}/>  
                {/* Configuracion */} 
                <Route path="cnf/modulos"  element={<Modulosfrm />}/>       
              </Route>
            </Route>
            {/* Errores */}
            <Route path="*" element={<Error404 />}></Route>
          </Routes>
        </HashRouter>
        {/*<Dashboard
        usuario={usuario}
        setAuthState={setAuthState}
        setUsuario={setUsuario}
    />*/}
      </div>
    );
}

export default App;
