import { React} from "react";
import Sidebar2 from "../components/Sidebar2";

import Splash2 from "../paginas/Admin/Esc/Splash2";
const LayoutSplash = ({autusuario}) => {
  return (
    <div className="flex">   
      <Sidebar2 autusuario={autusuario}/>
      <div className="w-full  text-grisSinestry font-semibold">
      <Splash2 autusuario={autusuario}/>
      </div>
    </div>
  );
};
export default LayoutSplash;
