import { React } from "react";

import logofooter from "./logocrilumblanco.png";
const Footerlogin = () => {
  return (
    <footer className="w-[100vw] items-center flex flex-col justify-between 
        bg-moradoSinestry text-white
        sm:flex-row sm:items-center
        md:flex-row md:items-center      
        lg:flex-row">
      <div className="pl-3 h-[5vh] w-[10vw]"><img src={logofooter} alt=""/></div>
      <div>
        <ul className="h-[9vh] pb-5 text-[.7rem] flex flex-col gap-10  pr-5 items-center justify-center
        sm:flex-row sm:h-[9vh] pb-1
        md:flex-row md:h-[9vh]   
        lg:flex-row lg:h-[9vh] 
        xl:flex-row xl:h-[9vh] xl:pb-1">
          <li><a href="https://sinestry.com/aviso-de-privacidad/" target="_blank" rel="noopener noreferrer">Aviso de Privacidad</a></li>
          <li>Política SGI</li>
          <li>POWER BY © SINESTRY 2024</li>
        </ul>
      </div>
    </footer>
  );
};
export default Footerlogin;
