
const initialForm = {
    id: '',
    titulo: '',
    spaces: false,
    clave: '',
    url: '',
    win: '',
    icono: '',
    submenu: false
}
const Modulosfrm = () => {
    return (
        <>ejemplo</>
    )
}
export default Modulosfrm