import { Link } from "react-router-dom";
import logosinestry from "../../../auth/Login/logocrilumafirme.svg";

import { React, useState } from "react";

import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { PiNotepadBold } from "react-icons/pi";
import { IoLogoWhatsapp } from "react-icons/io";

import { FaChartPie } from "react-icons/fa";
import rolesPermisos from "../../../data/rolesPermisos"
const Splash2 = ({ autusuario }) => {

  //const nick=autusuario.uid
  const nick="admin"
  
   const [expanded, setExpanded] = useState(true);
   const [values, setValues] = useState({});
  const email = autusuario.email;


 
  // Función para actualizar los valores de forma dinámica
  const handleChange = (key, value) => {
    setExpanded(true)
    setValues(prevValues => ({
      ...prevValues,
      [key]: value
    }));
  };
  return (<>
    <div className="container  justify-center">
      <div
        className="absolute top-[10vh] w-[60%] rounded-[2.5vw] shadow-gray-400 shadow -mt-[2vh] ml-[13vw] 
             flex flex-col justify-center"
      >
        <div className="px-[20vw] pt-5">
          <img src={logosinestry} alt="" />
        </div>
        <div className="w-full flex flex-row justify-center">
          <span className="text-[1.5rem] justify-center items-center font-bold text-moradoSinestry">
            Bienvenido a Sinestry App
          </span>
        </div>
        <div className="w-full flex justify-center ">
          <div className="w-full gap-2 px-10">
            <p className="py-1 text-justify text-[.8rem]">
            Aquí encontrarás información para la atención, seguimiento eficiente y oportuno de los siniestros de cistales correspondientes al Banco del Bienestar.
            </p>
            <p className="py-1 text-justify"></p>
          </div>
        </div>
        <div className="w-full pl-10 items-center grid grid-cols-3  py-[2vh] text-[1.2rem]">
{/*  */}

{rolesPermisos[nick].menus.map((itemmenu) => (  
  <>
  <div 
  onClick={(e) => handleChange(itemmenu.clave, !values[itemmenu.clave])} 
  key={itemmenu.id} 
  >
 <Link to={itemmenu.url} target={itemmenu.win===""?"":itemmenu.win}  className={`flex flew-row justify-around z-50 items-center p-1 text-[.8rem] gap-2  mb-2 bg-moradoSinestry h-8 col-1 mr-5 w-[18vw] text-white rounded-[15vh]`}><span>{itemmenu.icono}</span>
  <span>{itemmenu.titulo}</span>
  <span className="text-[.8rem] px-4 pt-2 block float-left">{values[itemmenu.clave]?<FaChevronUp/>:<FaChevronDown/>}</span>
  </Link></div>
 </>
))}      
        </div>
      </div>
      
    </div>
   
    </> );
};
export default Splash2;
