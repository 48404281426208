import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { getStorage ,ref,uploadBytes} from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyAdEC4fSmflN78jOC7KmN58D00BKM6GvaA",
  authDomain: "crilumsynestry.firebaseapp.com",
  projectId: "crilumsynestry",
  storageBucket: "crilumsynestry.appspot.com",
  messagingSenderId: "554211976300",
  appId: "1:554211976300:web:7e3093bccc6dc928a02449"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); // auth 

export const storage = getStorage(app);// storage 

export function upfileFirebase(file) {
 const strageRef = ref(storage, file.name);
  uploadBytes(strageRef, file).then((snapshot) => {

  })
}
const provider = new GoogleAuthProvider()
export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      
      })
    .catch((err) => {});
};
