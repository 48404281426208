import { React } from "react";

import Footerlogin from "./Footerlogin";
import Conteneidologin from "./Conteneidologin";
const Login = ({ setAuthState, setUsuario }) => {
  return (
    <div className="flex flex-col h-screen">
      <Conteneidologin/>
      <Footerlogin />
    </div>
  );
};
export default Login;
