import { FaChevronDown, FaChevronUp ,FaChartPie } from "react-icons/fa";
import { PiNotepadBold } from "react-icons/pi";
import { HiHome, HiMenu } from "react-icons/hi";

import { IoLogoWhatsapp } from "react-icons/io";
const rolesPermisos = {
    admin: {
      menus: [
        {
          id: 1,
          spaces: false,
          titulo: "Siniestralidad",
          clave:"siniestralidad",
          url: "/sin/siniestralidad",
          win:"",
          icono: <PiNotepadBold/>,
          submenu: false,
          submenus: [],
        }, 
        {
          id:31,
          spaces: true,
          titulo: "Estadísticas",
          clave:"estadisticas",
          url: "/sin/reporte1",
          win:"", 
          icono: <FaChartPie />,
          submenu: false,
          submenus: [],
        },
        {
          id:31,
          spaces: true,
          titulo: "Whatsapp",
          clave:"whatsap",
          url: "https://api.whatsapp.com/send?phone=5215578786789&amp;text=%C2%A1Hola%2C%20bienvenido%20al%20servicio%20de%20SINESTRY!%20%0AA%20trav%C3%A9s%20de%20este%20canal%2C%20podremos%20resolverte%20cualquier%20duda%20que%20tengas%20para%20la%20atenci%C3%B3n%20de%20siniestros.%0AEstamos%20aqu%C3%AD%20para%20ayudarte.%20%20%0A%C2%A1Gracias!",
          win:"_blank",  
          icono: <IoLogoWhatsapp/>,
          submenu: false,
          submenus: [],
        },       
      ],
    },
  };
  export default rolesPermisos;